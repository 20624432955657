import '../scss/style.scss';
import 'jquery';
import './modules/cookie';

$(document).ready(function () {

    $('.menu-toggle').on('click', function () {
        $('.nav__items').toggleClass('nav__items--open');
        $(this).toggleClass('open');
    });

    const navPage = $('.nav__items');
    // console.log(navPage.length);

    navPage.on('click', '.drop', function (e) {
        e.preventDefault();
        //console.log(e);
        let item = $(this).closest('li');
        //console.log(item);
        item.toggleClass('open');
        item.children('ul').slideToggle();
        // item.closest('ul').children('li').not(item).find('ul').slideUp();
        // sn.find('.open').children('ul').show();
    })




})
